import { useCallback, useEffect, useState } from 'react'
import dynamic from 'next/dynamic'

import type { SitewideBanner } from '@/types/Contentful/SitewideBanner'

const BannerV1 = dynamic(import('./index'))

type ScrollableBannerProps = Pick<SitewideBanner, 'tiles' | 'countdownTarget'>

export const ScrollableBanner: React.FC<ScrollableBannerProps> = ({
  tiles = [],
  countdownTarget,
}) => {
  const [index, setIndex] = useState(0)
  const [currentInterval, setCurrentInterval] = useState<NodeJS.Timeout | null>(null)

  const incrementIndex = useCallback(() => setIndex((i: number) => (
    i + 1 >= tiles.length ? 0 : i + 1
  )), [tiles.length])

  const configureInterval = useCallback(() => {
    if (tiles.length > 1) {
      setCurrentInterval((interval) => {
        if (interval) {
          clearInterval(interval)
        }
        return setInterval(incrementIndex, 5000)
      })
    }
  }, [tiles.length, incrementIndex])

  useEffect(() => {
    configureInterval()
  }, [configureInterval])

  const onArrowClicked = (i: number) => {
    if (i > tiles.length || i < 0 || Number.isNaN(i)) {
      setIndex(0)
    } else {
      setIndex(i)
    }
    if (currentInterval) {
      clearInterval(currentInterval)
    }
    setTimeout(configureInterval, 5000)
  }
  const onTogglePlayPause = () => {
    if (currentInterval) {
      clearInterval(currentInterval)
      setCurrentInterval((interval) => {
        try {
          if (interval) {
            clearInterval(interval)
          }
        } catch (err) {
          // Fail silently
        }
        return null
      })
    } else {
      setTimeout(configureInterval, 500)
    }
  }
  return (
    <BannerV1
      scrollable
      isScrolling={!currentInterval}
      onSelectTile={onArrowClicked}
      onTogglePlayPause={onTogglePlayPause}
      messaging={tiles[index].internalName}
      callToActionText={tiles[index].callToActionText}
      callToActionUrl={tiles[index].callToActionUrl}
      countdownTarget={countdownTarget}
      tilesLenght={tiles.length}
      selectedIndex={index}
    />
  )
}

export default ScrollableBanner
